import * as React from "react"
import { Breadcrumb, Container, Stack } from "react-bootstrap"
import { PageProps, graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import ReactAudioPlayer from "react-audio-player"

import Layout from "../components/layout"

import { fixName } from "../../utils/name_utils"

type DataProps = {
  imageSharp: {
    gatsbyImageData: IGatsbyImageData
  }
}

type ContextProps = {
  name: string
}

const NameTemplate: React.FC<PageProps<DataProps, ContextProps>> = ({
  data,
  pageContext,
}) => {
  const initial = pageContext.name.substring(0, 1)

  return (
    <Layout pageTitle={fixName(pageContext.name)}>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href={`/browse/${initial.toLowerCase()}`}>
          Names starting with the letter {initial}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{fixName(pageContext.name)}</Breadcrumb.Item>
      </Breadcrumb>
      <Container>
        <Stack>
          <div className="d-flex justify-content-center">
            <GatsbyImage
              image={data.imageSharp.gatsbyImageData}
              alt={`${pageContext.name} SoundName`}
            />
          </div>
          <div className="d-flex justify-content-center">
            <ReactAudioPlayer src={`/sound/${pageContext.name}.mp3`} controls />
          </div>
        </Stack>
      </Container>
    </Layout>
  )
}

export default NameTemplate

export const query = graphql`
  query SoundName($regexName: String) {
    imageSharp(parent: { internal: { description: { regex: $regexName } } }) {
      gatsbyImageData(width: 800)
    }
  }
`
